<template>
  <main class="modern-content">
    <p>Experience a sleek and efficient modern web development service.</p>
    <CustomButton :onClick="scrollToContact">Contact Us</CustomButton>
    <CustomButton :onClick="scrollToServices">Subscribe</CustomButton>
    <div class="spacer"></div>
    <div id="about">
      <CustomCard
        :media="require('@/assets/Bulletin.mp4')"
        title="Whats Different?"
        content="A development experience for clients and engineers alike, to accomplish that we focus on the most productive part element of the process: Collaboartion"
        alignment="right"
      />
      <MethodComparison />
      <CustomCard
        :media="require('@/assets/TaskList.mp4')"
        title="How We Do It"
        content="By eliminating all the friction points around feature requests and scope pushback we can spend our time and effort on outcomes. with our process, we build it and then you revise it until youre happy."
        alignment="left"
      />
    </div>
    <div class="spacer"></div>
    <div id="services">
      <PlansComparison />
      <div class="spacer"></div>
      <CustomCard
        :media="require('@/assets/Comparison.mp4')"
        title="Less Meetings, More Work"
        content="Remove the admin work and meeting scheduling. your job board is a direct line to us. See the progress of your tickets, create a backlog and watch them dissapear in real-time."
        alignment="right"
      />
      <CustomCard
        :media="require('@/assets/WhatWeDo.mp4')"
        title="What we can do"
        content="Web Applications, Mobile Applications, Development Continuation, API Integrations, DevOps, Consulting"
        alignment="left"
      />
    </div>
    <div class="spacer"></div>
    <div id="contact">
      <ContactCard />
    </div>
  </main>
</template>

<script>
import ContactCard from '../utilities/ContactCard.vue';
import CustomButton from '../utilities/CustomButton.vue';
import CustomCard from '../utilities/CustomCard.vue';
import MethodComparison from '../utilities/MethodComparison.vue';
import PlansComparison from '../utilities/PlansComparison.vue';

export default {
  name: 'ModernContent',
  components: {
    CustomButton,
    CustomCard,
    MethodComparison,
    PlansComparison,
    ContactCard
  },
  methods: {
    scrollToContact() {
      const contactElement = document.getElementById('contact');
      if (contactElement) {
        window.scrollTo({
          top: contactElement.offsetTop,
          behavior: 'smooth'
        });
      }
    },
    scrollToServices() {
      const servicesElement = document.getElementById('services');
      if (servicesElement) {
        window.scrollTo({
          top: servicesElement.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style scoped>
.modern-content {
  text-align: center;
  padding: 20px;
  color: #000;
  font-family: 'Arial', sans-serif;
}

.spacer{
  margin-top: 25px;
  margin-bottom: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
}
</style>
