<template>
  <div class="plans-comparison">
    <div class="pricing__left">
      <div class="pricing__left-top">
        <div class="available__pill">
          <div class="slots-available">
            <div class="heartbeat"></div>
            <div class="dot"></div>
            <p class="slots-text">Slots available</p>
          </div>
        </div>
        <h1 class="pricing__left-title">Join<br>Bright-Development</h1>
      </div>
      <div class="pricing__left-bottom">
        <a href="https://calendly.com/designjoy/designjoy-intro-1?month=2024-02" target="_blank" class="pricing__left-inside-card top w-inline-block">
          <div class="pricing__left-inside-card-left">
            <h5 class="pricing__left-card-header">Book a 15-min intro call</h5>
            <p class="pricing__left-card-text">Learn more about how Bright-Development works and how it can help you.</p>
          </div>
          <img src="" loading="lazy" alt="">
        </a>
        <a href="https://designjoy.getrewardful.com/signup" target="_blank" class="pricing__left-inside-card w-inline-block">
          <div class="pricing__left-inside-card-left">
            <h5 class="pricing__left-card-header">Refer a friend & earn</h5>
            <p class="pricing__left-card-text">Earn 5% monthly recurring commissions for each referral.</p>
          </div>
          <img src="" loading="lazy" alt="">
        </a>
      </div>
      <img src="" loading="lazy" alt="" class="image-79">
    </div>
    <div class="pricing__right-card">
      <h4 class="pricing__right-title">Membership</h4>
      <div class="tabs-container">
        <div class="tabs w-tabs">
          <div class="tabs-menu w-tab-menu" role="tablist">
            <div class="tab-switch">
              <a :class="['tab', { active: currentTab === 'standard' }]" @click.prevent="currentTab = 'standard'">Standard</a>
              <a :class="['tab', { active: currentTab === 'pro' }]" @click.prevent="currentTab = 'pro'">Expedited</a>
            </div>
          </div>
          <div class="tab-content">
            <div v-if="currentTab === 'standard'" class="tab-pane">
              <div class="pricing__right-top">
                <h4 class="price">$4,995<span class="per-month">/m</span></h4>
                <p class="plan-text">One request at a time. <span class="plan-note">Pause or cancel anytime.</span><br></p>
              </div>
              <div class="pricing__right-bottom">
                <p class="plan-header">What's included</p>
                <div class="w-layout-grid pricing__right-grid">
                  <ul role="list">
                    <li><p class="plan-text">One request at a time</p></li>
                    <li><p class="plan-text">Average 48 hour delivery</p></li>
                    <li><p class="plan-text">Unlimited projects</p></li>
                    <li><div class="div-block-24"><p class="plan-text">Web development</p></div></li>
                  </ul>
                  <ul role="list">
                    <li><p class="plan-text">Unlimited stock photos</p></li>
                    <li><p class="plan-text">Unlimited users</p></li>
                    <li><p class="plan-text">Pause or cancel anytime</p></li>
                  </ul>
                </div>
                <div class="pricing__right-cta-wrapper">
                  <div class="button__wrapper m-t-0">
                    <CustomButton v-if="currentTab === 'standard'" :onClick="redirectToStandard">Get started</CustomButton>
                    <CustomButton v-if="currentTab === 'pro'" :onClick="redirectToPro">Get started</CustomButton>
                  </div>
                  <div class="text-block-4">or <a href="https://calendly.com/brightember/" target="_blank" class="link-2">book a call</a></div>
                </div>
              </div>
            </div>
            <div v-else class="tab-pane">
              <div class="pricing__right-top">
                <h4 class="price">$7,995<span class="per-month">/m</span></h4>
                <p class="plan-text">Double the requests. <span class="plan-note">Pause or cancel anytime.</span><br></p>
              </div>
              <div class="pricing__right-bottom">
                <p class="plan-header">What's included</p>
                <div class="w-layout-grid pricing__right-grid">
                  <ul role="list">
                    <li><p class="plan-text"><span class="highlight">Two requests at a time</span></p></li>
                    <li><p class="plan-text">Average 48 hour delivery</p></li>
                    <li><p class="plan-text">Unlimited projects</p></li>
                    <li><div class="div-block-24"><p class="plan-text">Web development</p></div></li>
                  </ul>
                  <ul role="list">
                    <li><p class="plan-text">Unlimited stock photos</p></li>
                    <li><p class="plan-text">Unlimited users</p></li>
                    <li><p class="plan-text">Pause or cancel anytime</p></li>
                  </ul>
                </div>
                <div class="pricing__right-cta-wrapper">
                  <div class="button__wrapper m-t-0">
                    <CustomButton v-if="currentTab === 'standard'" :onClick="redirectToStandard">Get started</CustomButton>
                    <CustomButton v-if="currentTab === 'pro'" :onClick="redirectToPro">Get started</CustomButton>
                  </div>
                  <div class="text-block-5">or <a href="https://calendly.com/designjoy/designjoy-intro-1?month=2024-02" target="_blank" class="link-2">book a call</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '../utilities/CustomButton.vue';

export default {
  name: 'PlansComparison',
  components: {
    CustomButton
  },
  data() {
    return {
      currentTab: 'standard'
    };
  },
  methods: {
    redirectToStandard() {
      window.location.href = 'https://yeti-minis.myhelcim.com/hosted/?token=d3bc1a73cb76c2ebbc6d6e&recurringPlan=500e0d5b794cd28388adf9&amountHash=1d85c2a55f87e018de070a438da48b3c428d79d29441122a195eba9dd6654b21';
    },
    redirectToPro() {
      window.location.href = 'https://yeti-minis.myhelcim.com/hosted/?token=b34b5ae2b609c8afd4a2ad&recurringPlan=5a9d2727070e183e566604&amountHash=933e713281b548776c0225cc7a4f7aa413e63f727f0a26cfc5bf5201479c0fa3';
    }
  }
};
</script>

<style scoped>
.plans-comparison {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.pricing__left, .pricing__right-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 45%;
  max-width: 420px; /* Ensure max width of 420px */
}

.pricing__left {
  background: linear-gradient(#000, #292929);
  color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .31), inset 0 2px 1px rgba(255, 255, 255, .5);
  font-family: Brockmann Webfont, sans-serif;
}

.pricing__left-top, .pricing__right-top {
  margin-bottom: 20px;
}

.available__pill {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.slots-available {
  display: flex;
  align-items: center;
  position: relative;
}

.heartbeat, .dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
}

.heartbeat {
  opacity: 0.75;
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: green;
  z-index: 1;
  left: 7.5%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.slots-text {
  font-size: 1rem;
  color: #fff;
  margin-left: 35px;
}

.pricing__left-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.pricing__left-bottom, .pricing__right-bottom {
  margin-top: 20px;
}

.pricing__left-inside-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(#000, #292929);
  transition: background-color 0.3s;
}

.pricing__left-inside-card:hover {
  background-color: #333;
}

.pricing__left-inside-card-left {
  flex-grow: 1;
}

.pricing__left-card-header {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.pricing__left-card-text {
  font-size: 1.2rem;
  color: #fff;
}

.pricing__right-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.tabs {
  width: 100%;
}

.tabs-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-switch {
  background-color: #000;
  border-radius: 10px;
  padding: 5px;
  display: flex;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  margin: 0 5px;
  background-color: #f9f9f9;
  color: #000;
  transition: background-color 0.3s, color 0.3s;
}

.tab:hover {
  background-color: #f1f1f1;
}

.tab.active {
  background-color: #cf4242;
  color: #fff;
}

.price {
  font-size: 3rem;
  margin-bottom: 10px;
  font-family: 'Butler', sans-serif;
}

.per-month {
  font-size: 1.5rem;
  color: #888;
}

.plan-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000;
}

.plan-text {
  font-size: 1.2rem;
  color: #000;
}

.plan-note {
  font-size: 1rem;
  color: #666;
}

.w-layout-grid {
  display: flex;
  justify-content: space-between;
}

.w-layout-grid ul {
  list-style-type: none;
  padding: 0;
}

.w-layout-grid li {
  margin-bottom: 10px;
}

.cta-wrapper {
  text-align: center;
  margin-top: 20px;
}

.text-block-4, .text-block-5 {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #666;
}

.link-2 {
  color: #cf4242;
  text-decoration: none;
}

.link-2:hover {
  text-decoration: underline;
}
</style>
