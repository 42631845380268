<template>
    <header class="old-school-header">
      <img src="../../assets/netbrowse/oldschool-computer.gif" alt="Retro Gif Left" class="retro-gif-left">
      <h1>Welcome to BrightEmber's Website!</h1>
    </header>
  </template>
  
  <script>
  export default {
    name: 'OldSchoolHeader'
  };
  </script>
  
  <style scoped>
  .old-school-header {
    text-align: center;
    margin-top: 80px;
    padding: 20px;
    color: #00f;
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  .retro-gif-left, .retro-gif-right {
    width: 50px;
    height: 50px;
  }
  </style>
  