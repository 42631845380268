<template>
    <footer class="old-school-footer">
      <p>© 1999 BrightEmber GeoCities Website</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'OldSchoolFooter'
  };
  </script>
  
  <style scoped>
  .old-school-footer {
    text-align: center;
    padding: 20px;
    color: #00f;
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  </style>
  