<template>
    <div :class="['custom-card', alignmentClass]" :style="backgroundStyle">
      <div class="media-container">
        <img v-if="isImage" :src="media" alt="Card media" class="media">
        <video v-else :src="media" autoplay loop muted class="media"></video>
      </div>
      <div class="content-container">
        <h2 class="title">{{ title }}</h2>
        <p class="content">{{ content }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CustomCard',
    props: {
      media: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      },
      alignment: {
        type: String,
        default: 'left',
        validator: value => ['left', 'right'].includes(value)
      },
      backgroundOpacity: {
        type: Number,
        default: 0.5
      }
    },
    computed: {
      isImage() {
        return /\.(jpg|jpeg|png|gif|svg)$/.test(this.media);
      },
      alignmentClass() {
        return this.alignment === 'right' ? 'align-right' : 'align-left';
      },
      backgroundStyle() {
        return {
          background: `rgba(255, 255, 255, ${this.backgroundOpacity})`
        };
      }
    }
  };
  </script>
  
  <style scoped>
  .custom-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
    margin: 0 auto;
    max-width: 65%;
    position: relative;
    padding: 10px; /* Adding padding for bevel effect */
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), rgba(200, 200, 200, 0.5)); /* Transparent bevel effect */
  }
  
  .custom-card:hover {
    transform: translateY(-5px);
  }
  
  .align-left {
    flex-direction: row;
  }
  
  .align-right {
    flex-direction: row-reverse;
  }
  
  .media-container {
    width: 58%;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    padding: 10px; /* Padding to prevent the video from touching the edges */
    background: rgba(255, 255, 255, 0.1); /* Adding a slight background to the media container */
  }
  
  .media {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  
  .content-container {
    width: 42%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Butler', sans-serif;
  }
  
  .content {
    font-size: 1rem;
  }
  </style>
  