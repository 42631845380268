<template>
  <div :class="['footer-menu', { 'old-school': isOldSchool }]">
    <button
      class="footer-button"
      :class="{ active: activeSection === 'home', 'old-school-button': isOldSchool }"
      @click="navigate('home')"
    >
      Home
    </button>
    <button
      class="footer-button"
      :class="{ active: activeSection === 'about', 'old-school-button': isOldSchool }"
      @click="navigate('about')"
    >
      About
    </button>
    <button
      class="footer-button"
      :class="{ active: activeSection === 'services', 'old-school-button': isOldSchool }"
      @click="navigate('services')"
    >
      Services
    </button>
    <button
      class="footer-button"
      :class="{ active: activeSection === 'contact', 'old-school-button': isOldSchool }"
      @click="navigate('contact')"
    >
      Contact
    </button>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  props: {
    isOldSchool: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeSection: 'home'
    };
  },
  methods: {
    navigate(section) {
      if (this.isOldSchool) {
        this.$emit('toggleExperience'); // Emit event to switch to modern mode
        setTimeout(() => {
          this.scrollToSection('home');
        }, 500); // Delay to ensure mode switch before scrolling
      } else {
        this.scrollToSection(section);
      }
    },
    scrollToSection(section) {
      this.activeSection = section;
      const element = document.getElementById(section);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
      } else if (section === 'home') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    handleScroll() {
      const sections = ['home', 'about', 'services', 'contact'];
      let currentSection = 'home';
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= pageHeight) {
        currentSection = 'contact';
      } else {
        sections.forEach(section => {
          const element = document.getElementById(section);
          if (element) {
            const rect = element.getBoundingClientRect();
            if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
              currentSection = section;
            }
          }
        });
      }
      this.activeSection = currentSection;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Initialize on mount
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
.footer-menu {
  background-image: linear-gradient(#000, #292929);
  border: 1px solid #000;
  position: fixed;
  font-family: Brockmann Webfont, sans-serif;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .31), inset 0 2px 1px rgba(255, 255, 255, .5);
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-around;
  width: auto;
  z-index: 1002;
}

.footer-menu.old-school {
  background-color: grey;
  background-image: none;
}

.footer-button {
  color: #fff;
  background: none;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-family: Brockmann Webfont, sans-serif;
  font-size: 17px;
  transition: background-color .3s ease;
  cursor: pointer;
}

.footer-button:hover,
.footer-button.active {
  background-color: #cf4242;
}

.footer-button.old-school-button {
  font-family: 'Times New Roman', serif;
  background-color: grey;
  color: black;
}
</style>
