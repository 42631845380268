<template>
  <div class="browser-header">
    <div class="browser-title-bar">
      <span class="browser-title">Welcome to Surfscape!</span>
      <div class="browser-controls">
        <div class="browser-control browser-control-minimize">🗕</div>
        <div class="browser-control browser-control-maximize">☐</div>
        <div class="browser-control browser-control-close" @click="$emit('close')">x</div>
      </div>
    </div>
    <div class="browser-menu-bar">
      <span>File</span>
      <span>Edit</span>
      <span>View</span>
      <span>Go</span>
      <span>Bookmarks</span>
      <span>Options</span>
      <span>Directory</span>
    </div>
    <div class="browser-toolbar">
      <div class="toolbar-button"><img src="../assets/netbrowse/back.jpg" alt="Back Button"></div>
      <div class="toolbar-button"><img src="../assets/netbrowse/forward.jpg" alt="Forward Button"></div>
      <div class="toolbar-button"><img src="../assets/netbrowse/home.jpg" alt="Home Button"></div>
      <div class="toolbar-button"><img src="../assets/netbrowse/reload.jpg" alt="Reload Button"></div>
      <div class="toolbar-button"><img src="../assets/netbrowse/images.jpg" alt="Images Button"></div>
      <div class="toolbar-button"><img src="../assets/netbrowse/open.jpg" alt="Open Button"></div>
      <div class="toolbar-button"><img src="../assets/netbrowse/find.jpg" alt="Find Button"></div>
      <div class="toolbar-button"><img src="../assets/netbrowse/stop.jpg" alt="Stop Button"></div>
    </div>
    <div class="browser-address-bar">
      <span>Location: http://home.brightember.com/home/welcome.html</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NetscapeHeader'
};
</script>

<style scoped>
.browser-header {
  background: #d4d0c8;
  border-bottom: 2px solid #999;
  font-family: 'Times New Roman', serif;
}
.browser-title-bar {
  background: #000080;
  color: #fff;
  padding: 2px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.browser-title {
  font-size: 14px;
}
.browser-controls {
  display: flex;
}
.browser-control {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  color: #000;
  background: #c0c0c0;
  border: 1px solid #999;
  cursor: pointer;
}
.browser-control:hover {
  background: #a0a0a0;
}
.browser-control-close {
  background: #ff5f57;
  color: #fff;
}
.browser-control-close:hover {
  background: #e00000;
}
.browser-menu-bar {
  display: flex;
  justify-content: flex-start;
  background: #c0c0c0;
  padding: 2px 10px;
  border-bottom: 1px solid #999;
  font-size: 12px;
}
.browser-menu-bar span {
  margin-right: 10px;
  cursor: pointer;
}
.browser-toolbar {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background: #d4d0c8;
  border-bottom: 1px solid #999;
}
.toolbar-button {
  margin-right: 5px;
  height: 55px;
}
.toolbar-button img {
  width: 65px;
  height: 55px;
}
.browser-address-bar {
  background: #fff;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #999;
  margin: 5px;
}
</style>
