<template>
    <div class="method-comparison">
      <div class="toggle-container">
        <span :class="{'label': true, 'past': isOldMethod, 'faded': isOldMethod}">Look into the past</span>
        <ToggleSwitch v-model="isOldMethod" :active-color="activeColor" />
        <span :class="{'label': true, 'future': isOldMethod, 'faded': !isOldMethod}">See into the future</span>
      </div>
      <div class="content-container">
       <div v-if="isOldMethod">
            Video of New method is here
       </div>
       <div v-if="!isOldMethod">
            Video of Old method is here
       </div>
      </div>
    </div>
  </template>
  
  <script>
  import ToggleSwitch from '../utilities/ToggleSwitch.vue';
  
  export default {
    name: 'MethodComparison',
    components: {
      ToggleSwitch
    },
    data() {
      return {
        isOldMethod: false,
        modernSteps: [
          'Subscribe to our service',
          'Submit a ticket',
          'We work on it until it\'s complete',
          'Submit another ticket!'
        ]
      };
    },
    computed: {
      activeColor() {
        return this.isOldMethod ? '#808080' : '#0000FF';
      }
    },
    mounted() {

    },
    methods: {
     
    },
    watch: {

    }
  };
  </script>
  
  <style scoped>
  .method-comparison {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 20px;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .label {
    font-family: 'Arial', sans-serif;
    font-size: 1.2rem;
    margin: 0 10px;
    transition: color 0.3s ease;
  }
  
  .past {
    color: rgb(73, 73, 73);
  }
  
  .future {
    color: rgb(73, 73, 73);
  }
  
  .faded {
    color: lightgrey;
  }
  
  .content-container {
    width: 80%;
    text-align: center;
  }
  
  .old-method,
  .new-method {
    font-family: 'Arial', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  h2 {
    font-family: 'Butler', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .old-method-diagram {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
  }
  
  .step {
    background-color: grey;
    color: white;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    border-radius: 5px;
    width: 150px;
    text-align: center;
  }
  
  .arrow {
    font-size: 2rem;
    margin: 5px;
  }
  
  .arrow.down {
    display: block;
    font-size: 2rem;
  }
  
  .modern-method-diagram {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle {
    position: relative;
    width: 300px;
    height: 300px;
    border: 5px solid #cf4242;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle-content {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .modern-step {
    width: 150px;
    padding: 10px;
    background-color: #7fffd4;
    border-radius: 10px;
    margin: 10px;
    font-family: 'Arial', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
  }
  </style>
  