<template>
    <div class="contact-card crt">
      <div class="contact-card-header">
        <span class="contact-card-title">Contact Us</span>
        <div class="contact-card-close" @click="closeCard">✖</div>
      </div>
      <div class="contact-card-content">
        <form name="contact" method="POST" data-netlify="true" netlify>
          <input type="hidden" name="form-name" value="contact">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" id="name" name="name" v-model="form.name" required />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" name="email" v-model="form.email" required />
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea id="message" name="message" v-model="form.message" rows="4" required></textarea>
          </div>
          <button type="submit" class="submit-button">Send Message</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactCard',
    data() {
      return {
        form: {
          name: '',
          email: '',
          message: ''
        }
      };
    },
    methods: {
      submitForm() {
        //alert(`Message sent!\nName: ${this.form.name}\nEmail: ${this.form.email}\nMessage: ${this.form.message}`);
        // Additional logic for form submission can be added here if needed
      },
      closeCard() {
        //alert('Card closed');
        // Add close logic here
      }
    }
  };
  </script>
  
  <style scoped>
  @import '../../crtEffect.css';
  
  .contact-card {
    background: #d4d0c8;
    border: 2px solid #999;
    font-family: 'Times New Roman', serif;
    width: 400px;
    margin: 20px auto;
    box-shadow: 4px 4px 0 rgb(116, 116, 116);
    position: relative;
  }
  
  .contact-card-header {
    background: #000080;
    color: #fff;
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #999;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .contact-card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .contact-card-close {
    color: #ff0000;
    cursor: pointer;
    font-size: 16px;
    padding: 0 5px;
  }
  
  .contact-card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .submit-button {
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #555;
  }
  </style>
  