<template>
  <main class="old-school-content">
    <p>Welcome to the official website of <blink>BrightEmber</blink>!</p>
    <p>We don't do development the old-school way with endless <blink>meetings</blink> and <blink>documents</blink>.</p>
    
    <h3 class="old-school-header">The Old-School Approach</h3>
    <table border="1" cellpadding="5" cellspacing="0" align="center" class="old-school-table">
      <tr>
        <td v-for="(step, index) in oldSchoolSteps" :key="index" :class="{'highlight': highlightedStep === index}">
          {{ step }}
        </td>
      </tr>
    </table>
    
    <h3>Our Approach</h3>
    <table border="1" cellpadding="5" cellspacing="0" align="center" class="approach-table">
      <tr>
        <td align="center">
          <div class="table-cell-content">
            <img src="../../assets/netbrowse/1.gif" class="number-gif-left" alt="Number 1">
            <img src="../../assets/netbrowse/oldschool-book.gif" alt="Subscribe Retro Gif" width="50" height="50">
            <p><blink>Subscribe</blink> to our service</p>
          </div>
        </td>
        <td align="center">
          <div class="table-cell-content">
            <img src="../../assets/netbrowse/2.gif" class="number-gif-left" alt="Number 2">
            <img src="../../assets/netbrowse/oldschool-moon.gif" alt="Ticket Retro Gif" width="65" height="55">
            <p>Submit a <blink>ticket</blink></p>
          </div>
        </td>
      </tr>
      <tr>
        <td align="center">
          <div class="table-cell-content">
            <img src="../../assets/netbrowse/3.gif" class="number-gif-left" alt="Number 3">
            <img src="../../assets/netbrowse/oldschool-programmer.gif" alt="Work Retro Gif" width="60" height="60">
            <p>We work on it until it's <blink>complete</blink></p>
          </div>
        </td>
        <td align="center">
          <div class="table-cell-content">
            <img src="../../assets/netbrowse/4.gif" class="number-gif-left" alt="Number 4">
            <img src="../../assets/netbrowse/oldschool-email.gif" alt="Ticket Retro Gif" width="80" height="80">
            <p>Submit another <blink>ticket</blink>!</p>
          </div>
        </td>
      </tr>
    </table>
    <br>
    <marquee>We don't make statements of work, change requests, or assessments. We make <b style="color: greenyellow;">websites!</b></marquee>
    
    <!-- Old School Pricing Section -->
    <h3 class="old-school-header">Pricing</h3>
    <div class="old-school-pricing">
      <div class="old-school-pricing-card">
        <div style="display: inline-flex;"><img style="height: 25px;" src="../../assets/netbrowse/oldschool-cabinet.gif"><h4>Standard Plan</h4></div>
        <p><b>$4,995</b><span class="per-month">/m</span></p>
        <p>One request at a time</p>
        <p>Average 48 hour delivery</p>
        <p>Unlimited projects</p>
        <p>Web development</p>
        <p>Unlimited stock photos</p>
        <p>Unlimited users</p>
        <p>Pause or cancel anytime</p>
      </div>
      <div class="old-school-pricing-card">
        <div style="display: inline-flex;"><img style="height: 25px;" src="../../assets/netbrowse/oldschool-graph.gif"><h4>Express Plan</h4></div>
        <p><b>$7,995</b><span class="per-month">/m</span></p>
        <p>Two requests at a time</p>
        <p>Average 48 hour delivery</p>
        <p>Unlimited projects</p>
        <p>Web development</p>
        <p>Unlimited stock photos</p>
        <p>Unlimited users</p>
        <p>Pause or cancel anytime</p>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'OldSchoolContent',
  data() {
    return {
      oldSchoolSteps: [
        "Project Document",
        "Needs Assessment",
        "Statement of Work",
        "Acceptance Phase",
        "Change Request",
        "Work Delivery Meeting",
        "Project Finishing"
      ],
      highlightedStep: -1
    };
  },
  mounted() {
    this.startHighlighting();
  },
  methods: {
    startHighlighting() {
      let stepIndex = 0;
      setInterval(() => {
        this.highlightedStep = stepIndex;
        stepIndex = (stepIndex + 1) % this.oldSchoolSteps.length;
      }, 1000); // Change interval to control speed of highlighting
    }
  }
};
</script>

<style scoped>
.old-school-content {
  text-align: center;
  padding: 20px;
  color: #00f;
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
marquee {
  color: #f00;
  font-size: 20px;
}
blink {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% { opacity: 0; }
}
.old-school-table {
  width: 100%;
  background-color: grey;
  color: black;
  font-family: 'Courier New', Courier, monospace;
}
.old-school-header {
  color: black;
  font-family: 'Courier New', Courier, monospace;
}
.old-school-table td {
  padding: 20px;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.old-school-table td.highlight {
  background-color: lightgrey;
  color: #000;
  animation: blinkHighlight 1s linear infinite;
}
@keyframes blinkHighlight {
  0%, 100% { background-color: lightgrey; }
  50% { background-color: rgb(214, 214, 214); }
}
.approach-table {
  width: 80%;
  table-layout: fixed;
  background-color: #7fffd4
}
.table-cell-content {
  position: relative;
}
.number-gif-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
}
.old-school-pricing {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.old-school-pricing-card {
  background-color: #ff0;
  border: 3px solid #000;
  padding: 10px;
  width: 300px;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 5px 5px #888888;
}
.old-school-pricing-card h4 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #f00;
}
.old-school-pricing-card p {
  font-size: 1em;
  margin: 5px 0;
}
.per-month {
  font-size: 0.8em;
}
</style>
