<template>
  <header class="modern-header">
    <div class="header-content">
      <img id="home" src="../../assets/belogo.png" alt="Logo" class="logo">
      <h1 class="title">
        Next-Gen Development, <span ref="flexibleText" class="flexible">Flexible</span> and Easy
      </h1>
      <p class="subtitle">Out with the old-school development, in with the new.</p>
      <p class="description">Development subscriptions for everyone. Pause or cancel anytime.</p>
      <CustomButton :onClick="seePlans">See plans</CustomButton>
      <div class="status">
        <div class="heartbeat"></div>
        <div class="dot"></div>
        Available now
      </div>
    </div>
  </header>
</template>

<script>
import { gsap } from "gsap";
import CustomButton from '../utilities/CustomButton.vue';

export default {
  name: 'ModernHeader',
  components: {
    CustomButton
  },
  methods: {
    seePlans() {
      alert('Redirect to plans page.');
    }
  },
  mounted() {
    gsap.to(this.$refs.flexibleText, {
      scaleX: 1.1,
      duration: 1,
      yoyo: true,
      repeat: -1,
      ease: "power1.inOut"
    });
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Arial:wght@700&display=swap');

.modern-header {
  background: url('../../assets/backgrounds/worn_dots.jpg') repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f8f8f8;
  font-family: 'Arial', sans-serif;
}

.header-content {
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.logo {
  width: 50px;
  height: 50px;
}

.title {
  font-family: 'Butler', sans-serif;
  font-size: 4rem;
  font-weight: 700;
  color: #000;
  margin: 20px 0;
}

.subtitle {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.flexible {
  display: inline-block;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 0.875rem;
  color: #999;
}

.heartbeat, .dot {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  
  background-color: green;
}

.heartbeat {
  opacity: 0.75;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: green;
  z-index: 1;
  left: -1.2%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
