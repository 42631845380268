<template>
    <footer class="modern-footer">
      <p>© 2024 Brightember</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'ModernFooter'
  };
  </script>
  
  <style scoped>
  .modern-footer {
    text-align: center;
    padding: 20px;
    color: #000;
    font-family: 'Arial', sans-serif;
  }
  </style>
  