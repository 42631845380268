<template>
  <div id="app">
    <header class="header">
      <img src="./assets/brightember_red_logo.svg" alt="Brightember Logo" class="logo">
      <h1 class="title">Brightember</h1>
    </header>
    <div :class="['browser-frame', showOldSchool ? 'old-school-size' : 'modern-size', showOldSchool ? '' : 'modern-fly-in']" :style="animationStyle">
      <NetscapeHeader class="crt" v-if="showOldSchool" @close="toggleExperience" />
      <div :class="['browser-content', showOldSchool ? 'crt' : '']">
        <div :class="['container', showOldSchool ? 'old-school' : 'modern']">
          <component :is="showOldSchool ? 'OldSchoolHeader' : 'ModernHeader'" />
          <component :is="showOldSchool ? 'OldSchoolContent' : 'ModernContent'" />
          <component :is="showOldSchool ? 'OldSchoolFooter' : 'ModernFooter'" />
        </div>
      </div>
    </div>
    <div v-if="showOldSchool" class="toggle-wrapper">
      <button 
        class="toggle-button modern-button middle-screen"
        @click="toggleExperience">
        It's time to upgrade your web experience
        <div class="modern-button-content">
          <img src="./assets/netbrowse/oldschool-mouse.gif">
          <p class="click-text">Click!</p>
        </div>
      </button>
    </div>
    <button 
      v-else
      class="toggle-button old-school-button bottom-right crt"
      @click="toggleExperience">
      Go Back In Time
    </button>
    <PageFooter :isOldSchool="showOldSchool" @toggleExperience="toggleExperience" />
  </div>
</template>

<script>
import gsap from 'gsap';
import OldSchoolHeader from './components/oldschool/OldSchoolHeader.vue';
import OldSchoolContent from './components/oldschool/OldSchoolContent.vue';
import OldSchoolFooter from './components/oldschool/OldSchoolFooter.vue';
import ModernHeader from './components/modern/ModernHeader.vue';
import ModernContent from './components/modern/ModernContent.vue';
import ModernFooter from './components/modern/ModernFooter.vue';
import NetscapeHeader from './components/NetscapeHeader.vue';
import PageFooter from './components/PageFooter.vue';  // Import PageFooter
import './crtEffect.css';

export default {
  components: {
    OldSchoolHeader,
    OldSchoolContent,
    OldSchoolFooter,
    ModernHeader,
    ModernContent,
    ModernFooter,
    NetscapeHeader,
    PageFooter  // Register PageFooter
  },
  data() {
    return {
      showOldSchool: true,
      animationStyle: {},
      shakeInterval: null,  // Add this line
    };
  },
  methods: {
    toggleExperience() {
      if (this.showOldSchool) {
        this.stopShakeAnimation(); // Stop the shake animation when transitioning to modern
        this.animateTransitionToModern();
      } else {
        this.animateTransitionToOldSchool();
        this.startShakeAnimation(); // Start the shake animation when transitioning to old school
      }
    },
    animateTransitionToModern() {
      this.animationStyle = {
        animation: 'crtShutdown 1s forwards',
      };

      setTimeout(() => {
        this.showOldSchool = false;
        this.animationStyle = {
          animation: 'modernFlyIn 1s forwards',
        };
      }, 1000);
    },
    animateTransitionToOldSchool() {
      this.animationStyle = {
        animation: 'modernFlyOut 1s forwards',
      };

      setTimeout(() => {
        this.showOldSchool = true;
        this.animationStyle = {
          animation: 'crtStartup 1s forwards',
        };
      }, 1000);

      setTimeout(() => {
        this.animationStyle = {};
      }, 2000);
    },
    startShakeAnimation() {
      const shakeAnimation = () => {
        if (this.showOldSchool) {
          gsap.fromTo(
            '.toggle-button',
            { zIndex: 1001, rotation: -1.8 },
            {
              zIndex: 1001,
              rotation: 1.8,
              duration: 0.1,
              yoyo: true,
              repeat: 5,
              onComplete: () => {
                gsap.to('.toggle-button', { rotation: 0, duration: 0.1 });
              }
            }
          );
        }
      };
      this.shakeInterval = setInterval(shakeAnimation, 10000);
    },
    stopShakeAnimation() {
      if (this.shakeInterval) {
        clearInterval(this.shakeInterval);
        this.shakeInterval = null;
      }
    }
  },
  mounted() {
    this.startShakeAnimation();
    function animate() {
      requestAnimationFrame(animate);
    }
    animate();
  }
};
</script>

<style>
@font-face {
  font-family: 'Butler';
  src: url('./assets/fonts/Butler-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@keyframes crtShutdown {
  0% {
    transform: scale(1, 1.3);
    filter: brightness(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3, 0.001);
    filter: brightness(10);
  }
  100% {
    transform: scale(0.0001, 0.0001);
    filter: brightness(0);
    opacity: 0;
  }
}

@keyframes crtStartup {
  0% {
    transform: scale(0.0001, 0.0001);
    filter: brightness(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.3, 0.001);
    filter: brightness(10);
  }
  100% {
    transform: scale(1, 1.3);
    filter: brightness(1);
    opacity: 1;
  }
}

@keyframes modernFlyIn {
  0% {
    transform: translateY(100vh);
  }
  70% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes modernFlyOut {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.1%, 100% {
    opacity: 0;
  }
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 13px;
}

.title {
  font-family: 'Butler', sans-serif;
  font-size: 28px; /* Reduced by 4 points */
  font-weight: bold;
  color: black;
}

.browser-frame {
  border: 2px solid #ccc;
  background: #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: width 0.5s ease, height 0.5s ease, border-radius 0.5s ease;
  margin-top: 20px;
}

.old-school-size {
  width: 1280px;
  height: 1000px; /* Minimum height set to 1000px */
  border-radius: 0;
  background: url('./assets/backgrounds/worn_dots.jpg') repeat;
}

.modern-size {
  width: 60%;
  min-width: 1000px;
  margin: 0 auto;
  height: calc(100% - 20px); /* 10px margin on top and bottom */
  border-radius: 10px;
  background: url('./assets/backgrounds/worn_dots.jpg') repeat;
}

.modern-fly-in {
  animation: modernFlyIn 1s forwards;
}

.browser-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.browser-content {
  overflow: auto;
  height: calc(100% - 60px);
  padding-top: 60px;
  background: url('./assets/backgrounds/worn_dots.jpg') repeat;
}

.container {
  min-height: 100%;
  transition: background 0.5s ease;
}

.old-school {
  background: url('./assets/backgrounds/cloudtile.jpg') repeat;
}

.modern {
  background: url('./assets/backgrounds/red_transparent_background.png');
  background: rgba(255, 0, 0, 0);
}

.toggle-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, bottom 0.5s ease, right 0.5s ease;
  z-index: 1001;
}

.modern-button {
  color: #fff;
  background-image: linear-gradient(#000, #292929);
  border: 1px solid #000;
  border-radius: 20px;
  padding: 1px 30px;
  font-family: Brockmann Webfont, sans-serif;
  font-size: 17px;
  transition: box-shadow .6s, background-color .6s;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .31), inset 0 2px 1px rgba(255, 255, 255, .5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modern-button:hover {
  background-color: #333;
}

.modern-button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modern-button img {
  margin-top: 15px;
  margin-left: 5px;
  width: 30px;
  height: 30px;
}

.click-text {
  margin-top: -5px;
  margin-left: 10px;
  animation: blink 0.7s infinite;
}

.old-school-button {
  background-color: #c0c0c0;
  color: #000;
  border: 2px solid #808080;
  box-shadow: inset 1px 1px 0 #fff, inset -1px -1px 0 #000;
}

.old-school-button:hover {
  background-color: #a0a0a0;
}

.middle-screen {
  position: fixed;
  top: 30%;
  left: 79%;
  transform: translate(-50%, -50%);
}

.bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.floating-text {
  position: absolute;
  top: 35%;
  left: 75%;
  margin-bottom: 10px; /* Add gap of 10px between text and button */
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
  font-size: 20px;
  color: #fff;
  animation: bobbing 2s infinite;
  z-index: 1001;
  text-align: center;
}

@keyframes bobbing {
  0%, 100% { transform: translateY(-20px); }
  50% { transform: translateY(-25px); }
}
</style>
