<template>
  <button @click="handleClick" class="custom-button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    onClick: {
      type: Function,
      required: true
    }
  },
  methods: {
    handleClick() {
      this.onClick();
    }
  }
};
</script>

<style scoped>
.custom-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  background-image: linear-gradient(#000, #292929);
  box-shadow: 0 2px 2px rgba(0, 0, 0, .31), inset 0 2px 1px rgba(255, 255, 255, .5);
  border: 1px solid #000;
  font-family: Brockmann Webfont, sans-serif;
  color: #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5);
  transition: background-color 0.3s ease;
}
.custom-button:hover {
  background-color: #6daa1a;
}
</style>
